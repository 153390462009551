import { Entity, FileModel, ImageModel, Nullable } from '@pinup-teams/common';

export enum Messengers {
  Telegram = 'telegram',
  Instagram = 'instagram',
  WhatsApp = 'whatsapp',
  LinkedIn = 'linkedin',
  FaceBook = 'facebook',
}

interface Link {
  name: string;
  link: string;
}

export interface BusinessCardSkin extends ImageModel {
  isDark: boolean;
}

export type CorporateLink = Link;

export type SegmentLink = Link;

export interface OwnBusinessCardSettings {
  segmentId: number;
  isActive: boolean;
  shareSlackAvatar: boolean;
  isDarkTheme: boolean;
  corporateLinks: Nullable<CorporateLink[]>;
}

export interface OwnBusinessCard {
  email: string;
  fullName: string;
  position: string;
  picture: string;
  pictureData: string;
  publicId: string;
  isActive: boolean;
}

export interface Segment extends Entity {
  name: string;
  logo: ImageModel;
}

export interface BusinessCardTag {
  id: number;
  name: string;
}

export interface BusinessCard {
  id: number;
  contactName: string;
  isFavorite: boolean;
  companyName: string | null;
  position: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  messengerType: string | null;
  messengerNickname: string | null;
  businessCardFile: FileModel | null;
  skin: BusinessCardSkin;
  tags: BusinessCardTag[];
}
