import { Entity, ImageModel, Nullable } from '@pinup-teams/common';
import { User, UserSlackId } from '@pt/models';

export enum SupportRequestStatusType {
  New = 'new',
  InProgress = 'inProgress',
  Completed = 'completed',
}

export enum SupportRequestCategoryType {
  Office = 'office',
  Platform = 'platform',
  Education = 'education',
  Finance = 'finance',
}

export interface SupportTip extends Entity {
  question: string;
  answer: string;
}

export interface SupportContact {
  text: string;
  images: ImageModel[];
}

export interface SupportHistoryStatus {
  status: SupportRequestStatusType;
  createdAt: string;
}

export interface SupportCategory {
  category: SupportRequestCategoryType;
}

export interface SupportRequestExtraInformation {
  officeFloor: Nullable<string>;
  locationName: Nullable<string>;
  officeSpace: Nullable<string>;
}

export interface SupportHistoryItem extends Entity, SupportHistoryStatus {
  comment: string;
  user: User;
}

export interface SupportRequest extends Entity, SupportHistoryStatus, SupportCategory {
  text: string;
  user: User;
}

export interface SupportRequestEditable extends Entity, SupportContact, SupportCategory,
  SupportHistoryStatus {
  user: User & UserSlackId;
  history: SupportHistoryItem[];
  extraInformation: Nullable<SupportRequestExtraInformation>;
}
