import { Entity } from '@pinup-teams/common';
import { UserProfile } from '@pt/models';

export enum GroupTypeEnum {
  Dynamic = 'dynamic',
  Individual = 'individual',
  FromTable = 'from_table',
}

export enum GroupByGroupFieldNameEnum {
  Location = 'location',
  Team = 'team',
  Position = 'position',
  Segment = 'segment',
  Status = 'status',
  Function = 'function',
  YearsInCompany = 'years_in_company',
  UserCourseParticipationCourseId = 'usercourseparticipation__course_id',
  UserCourseParticipationStatus = 'usercourseparticipation__status',
}

export enum GroupTagEnum {
  Courses = 'courses',
  FAQ = 'faq',
  Messages = 'messages',
  News = 'news',
  Pincoins = 'pincoins',
  Survey = 'survey',
  CoursesNotification = 'courses_notification',
}

export type GroupAdminType = `${GroupTypeEnum}`;

export interface GroupQuery {
  field: string;
  value: string;
}

export interface Group extends Entity {
  name: string;
}

export interface GroupAdmin extends Group {
  visible: boolean;
  usersCount: number;
  tagTypes: GroupTagEnum[];
}

export interface GoogleTable {
  id: string;
  name: string;
}

export interface GoogleSheet {
  id: number;
  sheetTitle: string;
}

export interface GroupGoogleTable {
  tableId: string;
  sheetId: number;
  column: string;
  row: number;
}

export interface GroupDetailsAdmin extends GroupAdmin {
  groupType: GroupAdminType;
  query?: GroupQuery[];
  users?: UserProfile[];
  googleTable?: GroupGoogleTable;
}

export interface GroupByGroup {
  fieldName: GroupByGroupFieldNameEnum;
  displayName: string;
  possibleValues: (string | [string, string])[];
}
