import { Entity } from '@pinup-teams/common';

export enum TgChatPermissions {
  Admin = 'administrator',
  Member = 'member',
}

export enum TgUserPermissions {
  Admin = 'administrator',
  Owner = 'owner',
  Member = 'member',
}

export enum TgUserStatuses {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum TgJiraStatuses {
  Probation = 'probation',
  Maternity = 'maternity',
  Paternity = 'paternity',
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
  Offboarding = 'offboarding',
  NoJira = 'noJira',
}

export enum TgChatTypes {
  User = 'user',
  Bot = 'bot',
  Channel = 'channel',
}

export interface TgChat extends Entity {
  tgId: number;
  title: string;
  membersCount: number;
  permissions: TgChatPermissions;
}

export interface TgUser extends Entity {
  tgId: number;
  username: string;
  firstName: string;
  lastName: string;
  userPermissions: TgUserPermissions;
  userStatus: TgUserStatuses;
  jiraStatus: TgJiraStatuses;
}

export interface TgSearchTag extends Entity {
  name: string;
}

export interface TgSearchChat extends Entity {
  title: string;
  usernames: string[];
  chatType: string;
  members: number | null;
  dbId: number | null;
  tags: TgSearchTag[] | null;
  comment: string | null;
}
