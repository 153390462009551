import { Entity, FileModel, ImageModel, Nullable } from '@pinup-teams/common';

import { Course } from './course.model';
import { Quest } from './quest.model';
import { RecommendationType } from './onboarding-template.model';
import { UserProfile } from './user.model';

export type OnboardingTaskConfirmationType = 'manual' | 'auto';
export type OnboardingTaskRequirementType = 'required' | 'optional';
export enum OnboardingTaskOwnershipType {
  Recommended = 'recommended',
  Own = 'own',
}

interface Questionnaire extends Entity {
  mainImage: Nullable<ImageModel>;
  name: string;
  reward: Nullable<number>;
}

export type OnboardingAutoTaskType = 'course' | 'quest' | 'questionnaire';

export interface BaseOnboardingTask extends Entity {
  name: string;
  taskType?: Nullable<OnboardingAutoTaskType>;
  recommendationType?: RecommendationType;
  usersCanEdit?: UserProfile[];
  usersCanNotEdit?: UserProfile[];
}

export interface OnboardingTask extends BaseOnboardingTask {
  description: string;
  confirmation: OnboardingTaskConfirmationType;
}

export interface UserOnboardingTask extends BaseOnboardingTask {
  completed: boolean;
}

interface TaskButton {
  name: string;
  url: string;
}

interface TaskPdfButton {
  name: string;
  pdf: FileModel;
}

export interface UserManualOnboardingTaskDetails extends OnboardingTask {
  confirmation: 'manual';
  image: ImageModel;
  videoUrl: string;
  completed: boolean;
  button: Nullable<TaskButton>;
  pdfButton: Nullable<TaskPdfButton>;
}

export interface UserAutoOnboardingTaskDetails extends OnboardingTask {
  confirmation: 'auto';
  completed: boolean;
  entity: (Quest & { type: 'quest' }) | (Course & { type: 'course' }) | (Questionnaire & {
    type: 'questionnaire';
  });
}

export type UserOnboardingTaskDetails = UserManualOnboardingTaskDetails
  | UserAutoOnboardingTaskDetails;

export interface AdminOnboardingTask extends BaseOnboardingTask {
  image: ImageModel;
  confirmation: OnboardingTaskConfirmationType;
  required: boolean;
  visible: boolean;
  editable: boolean;
  deletable: boolean;
}

export interface AdminManualOnboardingTaskDetails extends AdminOnboardingTask {
  confirmation: 'manual';
  videoUrl: string;
  image: ImageModel;
  description: string;
  button?: Nullable<TaskButton>;
  pdfButton?: Nullable<TaskPdfButton>;
}

export interface AdminAutoOnboardingTaskDetails extends Omit<AdminOnboardingTask, 'image'> {
  confirmation: 'auto';
  entity: {
    id: number;
    type: OnboardingAutoTaskType;
  };
  description: string;
}

export type AdminOnboardingTaskDetails = AdminManualOnboardingTaskDetails
  | AdminAutoOnboardingTaskDetails;

export type UserQuestionnaireStatus = 'inProgress' | 'inReview' | 'rejected' | 'published';

export interface UserInterview {
  url: string;
}
