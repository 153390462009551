import { Entity, ImageModel, Nullable } from '@pinup-teams/common';

import { User, UserGroup } from './user.model';

export enum MessageCategory {
  LAndD = 'learning-and-development',
}

export enum MessageStatus {
  Scheduled = 'scheduled',
  Sent = 'sent',
}

export interface MessageBaseModel extends Entity {
  groups: UserGroup[];
  images: ImageModel[];
  isScheduled: boolean;
  scheduledAt: string;
  status: MessageStatus;
  text: string;
}

export interface MessageModel extends MessageBaseModel {
  author: User;
  createdAt: string;
  isEditable: boolean;
  sentAt: Nullable<string>;
}

export type MessageListModel = Omit<MessageModel, 'author' | 'createdAt'>;

export interface AdminMessageModel extends MessageModel {
  category: MessageCategory;
}
